import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    CircularProgress,
    TablePagination,
    Button,
    Grid,
    TextField,
    InputAdornment,
} from "@mui/material";
import AdminHeader from "./AdminHeader";
import { PictureAsPdf, PictureAsPdfRounded } from "@mui/icons-material";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    Snackbar,
    Alert,
} from '@mui/material';
import { ToWords } from 'to-words';
import withAuth from "../HighOrderComponent/withAuth";
import apiUrl from "../Api/api";
import Search from "@mui/icons-material/Search";

const AdminStatements = () => {

    const receiptRef = useRef();

    const [receiptDetails, setReceiptDetails] = useState(null); // State for receipt details
    const [donations, setDonations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [downloading, setDownloading] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    // Handle the search input change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Fetch donations data
    useEffect(() => {
        const fetchDonations = async () => {
            try {
                const response = await axios.get("https://backend.govedafoundation.org/admin/statements");
                const sortedData = [...response.data].reverse(); // Reverse the order
                setDonations(sortedData);
            } catch (error) {
                console.error("Error fetching donations:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDonations();
    }, []);

    // Handle pagination
    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = (donation) => {

        setDownloading((prevState) => ({
            ...prevState,
            [donation.receipt_id]: true, // Set this donation as downloading
        }));

        const uniqueId = donation.uniqueId;
        axios.get(`${apiUrl}/admin/statement_receiptdownload/${uniqueId}`)
            .then((response) => {
                const donorDetails = response.data;

                // Set receipt details after fetching data
                setReceiptDetails({
                    ...donorDetails,
                    ...donation,
                    supportOption: donation.purpose === 'Others' ? donation?.other_purpose : donation?.purpose,
                    paymentDate: new Date(donation.payment_date).toLocaleString("en-GB", {
                        dateStyle: "short",
                    }),
                    amountinWords: new ToWords().convert(donation.amount), // Converting the amount to words
                });


                // Generate PDF after setting receipt details
                setTimeout(() => {
                    html2canvas(receiptRef.current, { scale: 2 }).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF({
                            orientation: 'portrait',
                            unit: 'mm',
                            format: 'a4'
                        });

                        const a4Width = 210; // mm
                        const a4Height = 297; // mm
                        const margin = 5; // 5mm margin

                        const imgWidth = a4Width - margin * 2; // Adjust for left and right margins
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;

                        let positionY = margin; // Starting position from top
                        pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, imgHeight);

                        // Check if the image height exceeds A4 page height
                        let heightLeft = imgHeight - (a4Height - margin * 2);
                        while (heightLeft >= 0) {
                            pdf.addPage();
                            positionY = heightLeft - imgHeight + margin; // Maintain top margin on new pages
                            pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, imgHeight);
                            heightLeft -= a4Height;
                        }

                        // Download the generated PDF
                        pdf.save(`govedareceipt_${donation.receipt_id}.pdf`);

                        setDownloading((prevState) => ({
                            ...prevState,
                            [donation.receipt_id]: false, // Set this donation as downloading
                        }));

                    });
                }, 2000); // Set delay to ensure state is set before downloading

            })
            .catch((error) => {
                console.error("Error fetching donation details:", error);
            });
    };


    const filteredDonations = donations.filter((donation) => {
        return (
            donation.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            donation.amount.toString().includes(searchQuery) ||
            donation.purpose.toLowerCase().includes(searchQuery.toLowerCase()) ||
            donation.payment_mode.toLowerCase().includes(searchQuery.toLowerCase()) ||
            new Date(donation.payment_date).toLocaleDateString().includes(searchQuery)
        );
    });


    return (
        <Box>
            <AdminHeader />
            <Container sx={{ mt: 10 }}>
                <Typography variant="h5" p={2} gutterBottom>
                    Admin Statements
                </Typography>

                <TextField
                    label="Search Donations"
                    variant="outlined"
                    sx={{ maxWidth: 300, marginBottom: 2, alignSelf: "right" }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />

                {/* Loader */}
                {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : filteredDonations.length > 0 ? (
                    <TableContainer component={Paper} sx={{ boxShadow: 3, maxHeight: 900 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell sx={{ bgcolor: "#eeee" }}><strong>Receipt ID</strong></TableCell>
                                    <TableCell sx={{ bgcolor: "#eeee" }}><strong>Name</strong></TableCell>
                                    <TableCell sx={{ bgcolor: "#eeee" }}><strong>Amount</strong></TableCell>
                                    <TableCell sx={{ bgcolor: "#eeee" }}><strong>Purpose</strong></TableCell>
                                    <TableCell sx={{ bgcolor: "#eeee" }}><strong>Payment Mode</strong></TableCell>
                                    <TableCell sx={{ bgcolor: "#eeee" }}><strong>Payment Date</strong></TableCell>
                                    <TableCell sx={{ bgcolor: "#eeee" }}><strong>Download</strong></TableCell>

                                    {/* <TableCell sx={{ bgcolor: "#eeee" }}><strong>Entried On</strong></TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredDonations
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((donation, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                backgroundColor:
                                                    index === 0 && page === 0
                                                        ? "rgba(0, 255, 0, 0.2)"
                                                        : index % 2 === 0
                                                            ? "inherit"
                                                            : "inherit",
                                            }}
                                        >
                                            <TableCell>{donation.receipt_id}</TableCell>
                                            <TableCell>{donation.name}</TableCell>
                                            <TableCell>  ₹ {donation.amount}.00 /-</TableCell>
                                            <TableCell>{donation.purpose || donation.other_purpose}</TableCell>
                                            <TableCell>{donation.payment_mode}</TableCell>
                                            <TableCell>
                                                {new Date(donation.payment_date).toLocaleString("en-GB", {
                                                    dateStyle: "short",
                                                })}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    startIcon={downloading[donation.receipt_id] ? (
                                                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                                                    ) : (
                                                        <PictureAsPdf />
                                                    )}
                                                    fullWidth
                                                    size="large"
                                                    onClick={() => handleDownload(donation)}
                                                    sx={{
                                                        bgcolor: downloading[donation.receipt_id] ? "#1976d2" : "#19492ded",  // Different background color when downloading
                                                        color: downloading[donation.receipt_id] ? "#fff" : "#fff", // Ensure text is white
                                                        padding: downloading[donation.receipt_id] ? '5px 20px' : '5px 10px', // Wider padding when downloading
                                                        borderRadius: 2,
                                                        fontWeight: 'bold',
                                                        textTransform: 'none',
                                                        display: 'flex',
                                                        justifyContent: 'center',  // Center content horizontally
                                                        alignItems: 'center',      // Center content vertically
                                                        cursor: downloading[donation.receipt_id] ? 'not-allowed' : 'pointer',  // Disable cursor when downloading
                                                        '&:hover': {
                                                            bgcolor: downloading[donation.receipt_id] ? "#212b67" : "#1976d2", // Hover effect changes when downloading
                                                        },
                                                    }}
                                                >
                                                    {downloading[donation.receipt_id] ? "Downloading..." : "Download"}
                                                </Button>

                                            </TableCell>

                                            {/* <TableCell>
                                                {new Date(donation.time_stamp).toLocaleString("en-GB", {
                                                    dateStyle: "short",
                                                    timeStyle: "short",
                                                    hour12: true
                                                })}
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        {/* Pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={donations.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                ) : (
                    <Typography variant="h6" color="textSecondary" align="center" sx={{ mt: 4 }}>
                        No data available.
                    </Typography>
                )}
            </Container>

            <Container>
                <Typography>Pdf Preview :</Typography>
                <Box sx={{ p: 3, maxWidth: 'lg', margin: '0 auto' }}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: 1.5,
                            border: '2px solid black',
                            m: 2,
                            width: '210mm',
                            height: '297mm',
                            boxSizing: 'border-box',
                        }}
                        ref={receiptRef}
                    >
                        <Grid container alignItems="center" sx={{ borderBottom: '1px solid grey', p: 1 }}>
                            <Grid item xs={6}>
                                <Box>
                                    <img src={require('../images/goveda-logo.png')} alt="GoVeda Logo" height={50} />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box textAlign="right">
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                        GoVeda Foundation
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: "black" }}>
                                        Functional Office: Shri Kamakoti Veda Shastra Vidyalaya, <br />
                                        Plot no.1, Nagammal Ave Rd, Narayanapuram,<br />
                                        Pallikaranai, Chennai, Tamil Nadu 600100. <br />
                                        Phone: 98416 26380 / 78240 46310
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: "black" }}>
                                        Email: admin@govedafoundation.org <br />
                                        Website: www.govedafoundation.org <br />
                                        Registration No: 397/2017/BK4
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{ my: 2 }}>
                            <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
                                Thank You Letter Cum Receipt
                            </Typography>

                            <Grid container alignItems="center" sx={{ mt: 2 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" align="left">
                                        Dear Shri <Box component="span" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{receiptDetails?.name}</Box>,
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sx={{ textAlign: 'right', fontSize: '0.9rem' }}>
                                    <Typography variant="subtitle1">
                                        Date: <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.paymentDate}</Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                            Thank you for your valuable contribution towards the cause of <Box component="span" sx={{ fontWeight: 'bold', }}>{receiptDetails?.supportOption}</Box>. We at GoVeda Foundation are
                            committed to serving humanity by preserving the age-old knowledge bestowed upon us by our Rishis and
                            forefathers.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                            We welcome you into this sathsanga of like-minded people who share their resources for activities that
                            will help leave a legacy for our future generations.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                            We also invite you to be volunteers for our various activities as and when required at your convenience.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                            We look forward to your continued patronage.
                        </Typography>

                        <Box sx={{ mt: 3, mb: 3, borderTop: '1px solid grey', p: 3 }} >
                            <Typography variant="h5" align='center' sx={{ fontWeight: 'bold', }}>Receipt</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }}>Receipt No.: {receiptDetails?.receipt_id}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }} align="right">Date: <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.paymentDate}</Box></Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                            Received with thanks from {receiptDetails?.prefix} <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.name}</Box>,
                            address: <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.address1}, {receiptDetails?.address2}, {receiptDetails?.city_name}, {receiptDetails?.state_name}, {receiptDetails?.pincode}</Box>,
                            the sum of Rupees <Box component="span" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{receiptDetails?.amountinWords}</Box> only towards <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.supportOption}</Box> by{" "}
                            {receiptDetails?.payment_mode === 'Cheque' && (
                                <>
                                    {" "}<Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.payment_mode}</Box> No.* <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.chequeNumber}</Box>, dated <Box component="span" sx={{ fontWeight: 'bold' }}>{new Date(receiptDetails?.chequeDate).toLocaleString("en-GB", { dateStyle: "short", timeZone: "UTC" })}</Box>, drawn on <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.govedaBank}</Box>
                                </>
                            )}
                            {receiptDetails?.payment_mode === 'Bank Transfer' && (
                                <Box component="span" sx={{ fontWeight: 'bold' }}>{" "}ONLINE</Box>
                            )}
                            {receiptDetails?.payment_mode !== 'Bank Transfer' && receiptDetails?.payment_mode !== 'Cheque' && (
                                <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.payment_mode}</Box>
                            )}
                            {receiptDetails?.ref_number && (
                                <>, Reference No. <Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.ref_number}</Box></>
                            )}.
                        </Typography>

                        <Grid container alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="subtitle1" align="left" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                    Rs.<Box component="span" sx={{ fontWeight: 'bold' }}>{receiptDetails?.amount}.00</Box>
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Box>
                                    <img src={require('../images/PDF Signature/ssurya signature-03.png')} alt="Signature" width={150} height={80} />
                                </Box>

                                <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }}>
                                    For GoVeda Foundation
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>


        </Box>
    );
};

export default AdminStatements;
