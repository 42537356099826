import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './css/App.css';
import './css/admin.css';

import LandingPage from './screens/LandingPage';
import About from './screens/About';
import Gallery from './screens/Gallery';
import Contact from './screens/Contact';
import AgnihotraVruddhi from './screens/Causes/AgnihotraVruddhi';
import AnnadanamPage from './screens/Causes/AnnadanamPage';
import SupportPatashalas from './screens/Causes/SupportPatashalas';
import VillageParayanam from './screens/Causes/VillageParayanam';
import Dashboard from './screens/Dashboard';
import DonationPage from './screens/DonationPage';
import LoginProtectedRoute from './components/LoginProtectedRoute';
import Rough from './screens/Rough';
import TermsandConditions from './screens/FooterContent/TermsandConditions';
import PrivacyPolicy from './screens/FooterContent/PrivacyPolicy';
import CancellationandRefund from './screens/FooterContent/CancellationandRefund';
import ShippingandDelivery from './screens/FooterContent/ShippingandDelivery';



import AdminLogin from './admin/AdminLogin';
import AdminDashboard from './admin/AdminDashboard';
import AdminProtectedroute from './admin/AdminProtectedroute';
import TotalDonors from './admin/TotalDonors';
import PaymentLinkForm from './admin/PaymentLinkForm';
import DownloadReceipt from './screens/DownloadReceipt';
import AdminReceiptCreator from './admin/ReceiptCreation/AdminReceiptCreator';
import AddDonor from './admin/AddDonor';
import AdminStatements from './admin/AdminStatements';

const App = () => {


  const AdminRoute = ({ element }) => (
    <AdminProtectedroute>
      {element}
    </AdminProtectedroute>
  );

  const DashboardRoute = ({ element }) => (
    <LoginProtectedRoute>
      {element}
    </LoginProtectedRoute>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingPage />}></Route>
        <Route path='about' element={<About />}></Route>
        <Route path='gallery' element={<Gallery />}></Route>
        <Route path='contact' element={<Contact />}></Route>
        <Route path='donate' element={<DonationPage />}></Route>
        <Route path='dashboard' element={<Dashboard />}></Route>
        <Route path='termsandconditions' element={<TermsandConditions />}></Route>
        <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>
        <Route path='cancellation-refund' element={<CancellationandRefund />}></Route>
        <Route path='shipping-delivery' element={<ShippingandDelivery />}></Route>


        {/* causes dropdown */}
        <Route path='causes/agnihotra-vruddhi' element={<AgnihotraVruddhi />}></Route>
        <Route path='causes/annadanam' element={<AnnadanamPage />}></Route>
        <Route path='causes/support-patashalas' element={<SupportPatashalas />}></Route>
        <Route path='causes/village-temple-parayanam' element={<VillageParayanam />}></Route>

        {/* protected route */}

        <Route path='dashboard/download-receipt' element={<DashboardRoute element={<DownloadReceipt />} />} />
        <Route path='rough' element={<Rough />} />

        {/* admin */}
        <Route path='admin' element={<AdminLogin />}></Route>

        <Route path='admin-dashboard' element={<AdminRoute element={<AdminDashboard />} />} />
        <Route path='admin-total-donors' element={<AdminRoute element={<TotalDonors />} />} />
        <Route path='admin-payment-operation' element={<AdminRoute element={<PaymentLinkForm />} />} />
        <Route path='admin-receipt-creation' element={<AdminRoute element={<AdminReceiptCreator />} />} />
        <Route path='admin-add-donor' element={<AdminRoute element={<AddDonor />} />} />
        <Route path='admin-reports-statement' element={<AdminRoute element={<AdminStatements />} />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
